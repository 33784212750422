<template>
	<div data-route>
		<h1 data-element="page-heading">
			About Bridge128
		</h1>
		<div data-element="main">
			<div
				data-element="featured-articles"
				v-if="getCategories"
			>
				<category-thumbnail
					v-for="(category, key) in getCategories"
					:key="key"
					:category="category"
				/>
			</div>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isPublic": true
		}
	}
</route>

<script>

	import CategoryThumbnail  from '@/components/knowledgebase/view/CategoryThumbnail';
	import api                from '@/services/api';

	export default {
		metaInfo: {
			title: 'About'
		},
		components: {
			CategoryThumbnail
		},
		data: () => ({
			categories: []
		}),
		computed: {
			getCategories () {
				if (!this.categories?.length) {
					return false;
				}
				return this.categories;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/about',
						text: 'About'
					}
				].filter(Boolean);
			}
		},
		created () {
			this.setCategories();
		},
		methods: {
			async setCategories () {
				const categories = await api.knowledgebase.getCategories({
					include: 'featured'
				});
				if (!categories) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.categories = categories;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-route] {
		[data-element='page-heading'] {
			@include cols(12);
			margin:0 0 rem(28);
			font-family:'Montserrat', sans-serif;
			font-size:rem(40);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-element='featured-articles'] {
			@include nested-grid;
			[data-component='category-thumbnail'] {
				@include nested-cols(12, 12);
				@include vertical-nested-gut(12);
				@include mq($above: 'xs', $below: 'sm') {
					@include nested-cols(6, 12);
					@include nested-gut(12);
					@include vertical-nested-gut(12);
					&:nth-of-type(2n) {
						margin-right:0;
					}
				}
				@include mq('sm') {
					@include nested-cols(4, 12);
					@include nested-gut(12);
					@include vertical-nested-gut(12);
					&:nth-of-type(3n) {
						margin-right:0;
					}
				}
			}
		}
	}

</style>
