<template>
	<section
		data-component="category-thumbnail"
	>
		<h1
			data-element="title"
		>
			<div data-element="icon-wrapper">
				<icon
					icon="info"
					colour="blue"
				/>
			</div>
			<span>
				{{ category.title }}
			</span>
		</h1>
		<div data-element="articles">
			<router-link
				data-element="article-link"
				v-for="(article, key) in category.featured"
				:key="key"
				:to="getArticleRoute(article)"
			>
				<div data-element="icon-wrapper">
					<icon
						icon="book"
					/>
				</div>
				<span>
					{{ article.title }}
				</span>
			</router-link>
		</div>
		<router-link
			data-element="link"
			:to="getCategoryRoute"
		>
			<span>
				More articles
			</span>
			<icon
				icon="chevron-left"
				colour="blue"
			/>
		</router-link>
	</section>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			category: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({}),
		computed: {
			getCategoryRoute () {
				if (!this.category?.slug) {
					return false;
				}
				return `/about/${this.category.slug}`;
			}
		},
		methods: {
			getArticleRoute (article) {
				if (!this.getCategoryRoute ||
						!article?.slug) {
					return false;
				}
				const articleSlug = article.slug;
				return `${this.getCategoryRoute}/${articleSlug}`;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='category-thumbnail'] {
		background-color:$c-brand-grey-lighter-6;
		padding:rem(16) rem(16) rem(22);
		border-radius:8px;
		[data-element='icon-wrapper'] {
			display:flex;
			align-items:flex-start;
			justify-content:center;
			flex-shrink:0;
			width:rem(38);
			margin-right:rem(12);
			height:auto;
		}
		[data-element='title'] {
			display:flex;
			align-items:center;
			margin-bottom:rem(16);
			[data-element='icon-wrapper'] {
				[data-component='icon'] {
					width:rem(32);
					height:auto;
				}
			}
			span {
				font-size:rem(20);
				font-weight:700;
				color:$c-brand-blue-lighter-1;
			}
		}
		[data-element='articles'] {
			margin-bottom:rem(16);
			[data-element='article-link'] {
				display:flex;
				align-items:center;
				margin-bottom:rem(8);
				&:last-child {
					margin-bottom:0;
				}
				[data-element='icon-wrapper'] {
					[data-component='icon'] {
						width:rem(22);
						height:auto;
					}
				}
				span {
					font-size:rem(18);
					font-weight:700;
				}
			}
		}
		[data-element='link'] {
			display:flex;
			align-items:center;
			margin-left:rem(50);
			span {
				font-size:rem(16);
				font-weight:700;
				color:$c-brand-blue;
			}
			[data-component='icon'] {
				flex-shrink:0;
				width:rem(18);
				height:auto;
				transform:rotate(180deg);
			}
		}
	}

</style>
